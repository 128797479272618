/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const LOGIN_USER_PERMISSION = "LOGIN_USER_PERMISSION";

export const USER_PERMISSION_SUCCESS = "USER_PERMISSION_SUCCESS";
export const GET_USER_PERMISSION = "GET_USER_PERMISSION";
export const ASK_FOR_PERMISSION = "ASK_FOR_PERMISSION";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export const PRODUCT_LIST = "PRODUCT_LIST"
export const PRODUCT_LIST_SUCCESS  = "PRODUCT_LIST_SUCCESS" 
export const PRODUCT_LIST_ERROR= "PRODUCT_LIST_ERROR"
export const PRODUCT_LIST_ASK_FOR = "PRODUCT_LIST_ASK_FOR"

export const SALESORDER_TEMPLATE = "SALESORDER_TEMPLATE"
export const SALESORDER_TEMPLATE_SUCCESS  = "SALESORDER_TEMPLATE_SUCCESS" 
export const SALESORDER_TEMPLATE_ERROR= "SALESORDER_TEMPLATE_ERROR"

export const SOLICITUDES_LIST ="SOLICITUDES_LIST"
export const SOLICITUDES_LIST_SUCCESS ="SOLICITUDES_SUCCESS"
export const SOLICITUDES_LIST_ERROR ="SOLICITUDES_ERROR"
export const ASK_FOR_SOLICITUDES_LIST ="ASK_FOR_SOLICITUDES"

export const REQUEST_ERROR = "REQUEST_ERROR"
export const ROL_ASSIGN = "ROL_ASSIGN"

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./bienes/actions"
export * from "./salesorder/actions"
export * from "./errors/actions"