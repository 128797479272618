
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { Auth } from '../../helpers/API';
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    GET_USER_PERMISSION,
    ASK_FOR_PERMISSION,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    notifyError
} from '../actions';

import {
    loginUserSuccess,
    getPermissionSuccess,
    getUserPermission,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    setUserRol
} from './actions';
import decode from 'jwt-decode'


export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
    await Auth.login(email, password)



function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
            localStorage.setItem('user_id', loginUser.access_token);
            yield put(loginUserSuccess(loginUser.access_token, history));
            history.push('/');
    } catch (error) {
        yield put(loginUserError(error.message));
    }

}


export function* watchLoginUserSuccess(){
    yield takeEvery(LOGIN_USER_SUCCESS, getUserCredentials )
}


const getPermissionAsync = async () =>
await Auth.data()

function* getUserCredentials({payload}) {
    const {history} =  payload;
    try {
        const loginUser = yield call(getPermissionAsync);
        yield put(getPermissionSuccess(loginUser));
        const token = yield select(state=> state.authUser.user)

        const {roles} = decode(token);
        const [rol] = roles
        yield put(setUserRol(rol))
    } catch (error) {
        yield put(notifyError(error,history))
    }
}

function* askForPermission({payload:history}) {
    const param = yield select((state=> state.authUser.permission))
    
    if(param === ""){
      yield put(getUserPermission(history));
    }
}

export function* watchPermissionRequest(){
    yield takeEvery(ASK_FOR_PERMISSION, askForPermission )
}

export function* watchGetPermission(){
    yield takeEvery(GET_USER_PERMISSION, getUserCredentials )
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await Auth.register(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push('/')
        } else {
            yield put(registerUserError(registerUser.message));

        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}



export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    history.push('/')
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('user_id');
    } catch (error) {
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    return await Auth.sendPasswordResetEmail(email)
        .then(user => user)
        .catch(error => error);
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (!forgotPasswordStatus) {
            yield put(forgotPasswordSuccess("success"));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    return await Auth.confirmPasswordReset(resetPasswordCode, newPassword)
        .then(user => user)
        .catch(error => error);
}

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode } = payload;
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
        if (!resetPasswordStatus) {
            yield put(resetPasswordSuccess("success"));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        // fork(watchLoginUserSuccess),
        fork(watchGetPermission),
        fork(watchPermissionRequest),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
    ]);
}