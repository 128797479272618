import {
    SALESORDER_TEMPLATE,
    SALESORDER_TEMPLATE_SUCCESS,
    SALESORDER_TEMPLATE_ERROR,
    SOLICITUDES_LIST,
    SOLICITUDES_LIST_SUCCESS,
    SOLICITUDES_LIST_ERROR,
  } from "../actions";

const INIT_STATE = {
  list: { data: [], loading: false, error: "", date:null },
  template: {
    error: "",
    loading: false,
    date: null,
    data: null,
  },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SALESORDER_TEMPLATE:
            return { ...state, template: {...state.template, loading: true, error: '' } };
        case SALESORDER_TEMPLATE_SUCCESS:
            return { ...state, template: {...state.template, loading: false, data: action.payload.template, error: '' } };
        case SALESORDER_TEMPLATE_ERROR:
            return { ...state, template: {...state.template, loading: false, data:null, error: action.payload.message } };   
        case SOLICITUDES_LIST:
          return {...state, list:{...state.list, loading: true, error:''}}
        case SOLICITUDES_LIST_SUCCESS:
          return {...state, list:{...state.list, loading:false, data: action.payload, error:'', date: Date.now()}}
        case SOLICITUDES_LIST_ERROR:
          return {...state, list:{loading: false, data:[], error: action.payload.message}}
        default: return { ...state };
    }
}
