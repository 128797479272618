import {
    PRODUCT_LIST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_ERROR
  } from "../actions";

const INIT_STATE = {
    list: [],
    loading: false,
    param:'',
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PRODUCT_LIST:
            return { ...state, loading: true, error: '', param: action.payload.search  };
        case PRODUCT_LIST_SUCCESS:
            return { ...state, loading: false, list: action.payload.products, error: '' };
        case PRODUCT_LIST_ERROR:
            return { ...state, loading: false, list: [], error: action.payload.message, param:'' };
       
        default: return { ...state };
    }
}
