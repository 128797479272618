import {
    SALESORDER_TEMPLATE,
    SALESORDER_TEMPLATE_SUCCESS,
    SALESORDER_TEMPLATE_ERROR,
    SOLICITUDES_LIST,
    SOLICITUDES_LIST_SUCCESS,
    SOLICITUDES_LIST_ERROR,
    ASK_FOR_SOLICITUDES_LIST
  } from "../actions";
  
  export const getTemplate = (search,history) => ({
    type: SALESORDER_TEMPLATE,
    payload: {search,history},
  });
  export const getTemplateSuccess = (template) => ({
    type: SALESORDER_TEMPLATE_SUCCESS,
    payload: {template},
  });
  export const getTemplateError = (message) => ({
    type: SALESORDER_TEMPLATE_ERROR,
    payload: { message },
  });
  export const getSolicitudes = (history) => ({
    type: SOLICITUDES_LIST,
    payload: history
  });
  export const getSolicitudesSuccess = (list) => ({
    type: SOLICITUDES_LIST_SUCCESS,
    payload: list,
  });
  export const getSolicitudesError = (message) => ({
    type: SOLICITUDES_LIST_ERROR,
    payload: { message },
  });
  export const askForSolicitudes = (history) => ({
    type: ASK_FOR_SOLICITUDES_LIST,
    payload: history
  });