import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { Bienes } from "../../helpers/API"
import {
    PRODUCT_LIST,
    PRODUCT_LIST_ASK_FOR
} from '../actions';

import {
    getProducts,
    getProductsSuccess,
    getProductsError
} from './actions';
import {notifyError} from "../errors/actions"


export function* watchGetProducts() {
  yield takeEvery(PRODUCT_LIST, getProductList);
}

const getProductListAsync = async (search) =>
await Bienes.getList(search)

function* getProductList({ payload }) {
  const {search, history} = payload
  try {
    const productList = yield call(getProductListAsync, search);
    yield put(getProductsSuccess(productList));
  } catch (error) {
    yield put(getProductsError(error.message));
    yield put(notifyError(error,history))
  }
}

function* askForProductList({ payload }) {
    const {search, history} = payload
    const param = yield select((state=> state.bienes.param))
    if(param !== search){
      yield put(getProducts(search,history));
    }
}

export function* watchAskForProducts() {
  yield takeEvery(PRODUCT_LIST_ASK_FOR, askForProductList);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetProducts),
        fork(watchAskForProducts)
    ]);
}