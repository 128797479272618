
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  REQUEST_ERROR
} from '../actions';


import { logoutUser } from "../actions"


export function* watchErrors() {
  yield takeEvery(REQUEST_ERROR, reactToError);
}


function* reactToError({ payload }) {
  const { history, error } =  payload
  try {
    if(error.name === "401"){
      yield put(logoutUser(history));
    }
  } catch (error) {}
}

export default function* rootSaga() {
    yield all([
        fork(watchErrors)
    ]);
}