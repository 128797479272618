import {
    REQUEST_ERROR
  } from "../actions";

const INIT_STATE = {
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REQUEST_ERROR:
            return { error: action.payload.error.message };         
        default: return { ...state };
    }
}
