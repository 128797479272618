import { BASE_URL } from "../constants/defaultValues"

const ApiLogin = (email, password)=>{
    return fetch(`${BASE_URL}/api/login`,{
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body:JSON.stringify({username: email, password})
    })
    .catch(handleNetworkError)
    .then(handleResponseStatus)
}

const UserAuthorization = ()=>{
    return apiRequest("/api/usuario")
}

const ApiRegister = (email, password) =>{
    return fetch(`${BASE_URL}/api/guest/registro`,{
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify({username: email, password})
    })
    .catch(handleNetworkError)
    .then(handleResponseStatus)
}

export const Auth = {
    login: ApiLogin,
    register: ApiRegister,
    data: UserAuthorization
}


const getBienes = async (partida)=>{
    return apiRequest(`/api/bienes/${partida}`)
}



export const Bienes = {
    getList: getBienes,
}


const getTemplate = async () =>{
    return apiRequest("/api/solicitud/template")       
}

const postSolicitud = async (orderData)=>{
    return apiRequest(`/api/solicitud`, {
         method: 'POST',
         headers: {"Content-Type":"application/json"},
         body:JSON.stringify(orderData),
         })
}
const putSolicitud = async (orderData)=>{
    return apiRequest(`/api/solicitud`, {
         method: 'PUT',
         headers: {"Content-Type":"application/json"},
         body:JSON.stringify(orderData),
         })
}

const activarSolicitud = (id)=> (apiRequest(`/api/solicitud/activar/${id}`,{
    method: "POST",
    headers: {"Content-Type":"application/json"}
}))
const aprobarSolicitud = (id)=> (apiRequest(`/api/solicitud/aprobar/${id}`,{
    method: "POST",
    headers: {"Content-Type":"application/json"}
}))
const surtirSolicitud = (id)=> (apiRequest(`/api/solicitud/surtir/${id}`,{
    method: "POST",
    headers: {"Content-Type":"application/json"}
}))

const getList = async (param)=>{    
    return apiRequest(`/api/solicitud${param?`/${param}`:''}`)    
}

const getSolicitud = (id) =>{
    return apiRequest(`/api/solicitud/item/${id}`)
}

export const Salesorder = {
    getTemplate: getTemplate,
    create: postSolicitud,
    update: putSolicitud,
    getSolicitud,
    list: getList,
    activar: activarSolicitud,
    aprobar: aprobarSolicitud,
    surtir: surtirSolicitud
}

const getReporteProduccion = ()=>{
    return apiRequest("/api/reports/production/active")
}

const getReporteEntregas = (date)=>{
    return apiRequest(`/api/reports/delivery/${date}`)
}

export const Reports = {
    produccion: getReporteProduccion,
    entregas: getReporteEntregas 
}

/*  COMMON FUNCTIONS */
function apiRequest(path, options = {}) {
    const access_token = localStorage.getItem('user_id');
    return fetch(`${BASE_URL}${path}`,{
        ...options, 
        // credentials:'include',
        headers: {...options.headers, 
            // "Content-Type":"application/json"
            "Authorization":`Bearer ${access_token}`
        }
    })
    .catch(handleNetworkError)
    .then(handleResponseStatus)
}

const handleNetworkError = (error)=>{
    if(error.name === "AbortError"){
        throw new Error("RequestCancelada")
    }else{
        throw new Error("Error al conectar con el servidor")
    }
}
const handleResponseStatus = (response) =>{
    if(response.status === 200){
        return response.json()
    }
    if(response.status === 401){
        let e = new Error("Sesión expirada")
        e.name = "401"
        throw e
    }
    if(response.status >499){
        throw new Error(response.status + " " + response.statusText)
    }
    else if(response.status > 299 && response.status<500)
    {
        return response.json().then(({message})=>{
            throw new Error(message)
            })
    }
}