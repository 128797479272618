
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { Salesorder } from "../../helpers/API"
import {
    SALESORDER_TEMPLATE,
    SOLICITUDES_LIST,
    ASK_FOR_SOLICITUDES_LIST
} from '../actions';

import {
    getTemplateSuccess,
    getTemplateError,
    getSolicitudes,
    getSolicitudesError,
    getSolicitudesSuccess
} from './actions';
import {notifyError} from "../errors/actions"
import decode from 'jwt-decode'


export function* watchGetTemplate() {
  yield takeEvery(SALESORDER_TEMPLATE, getSalesorderTemplate);
}

const getSalesorderTemplateAsync = async (search) =>
await Salesorder.getTemplate(search)

function* getSalesorderTemplate({ payload }) {
  const {search, history} = payload
  try {
    const template = yield call(getSalesorderTemplateAsync, search);
    yield put(getTemplateSuccess(template));
  } catch (error) {
    yield put(getTemplateError(error.message));
    yield put(notifyError(error, history))
  }
}

export function* watchGetSolicitudes() {
  yield takeEvery(SOLICITUDES_LIST, getSolicitudesList);
}

const getSolicitudesListAsync = async () =>
await Salesorder.list()

const getSolicitudesUserListAsync = async (user) =>
await Salesorder.list(user)

function* getSolicitudesList({ payload:history }) {
  try {
    const token = yield select(state=>state.authUser.user)
    const {roles} = decode(token)
    if(roles[0]==="ROLE_SOL"){
      const user_id = yield select(state => state.authUser.permission)
      const list = yield call(getSolicitudesUserListAsync, user_id.id);
      yield put(getSolicitudesSuccess(list));
    }else{
      const list = yield call(getSolicitudesListAsync);
      yield put(getSolicitudesSuccess(list));
    }
  } catch (error) {
    yield put(getSolicitudesError(error.message));
    yield put(notifyError(error, history))
  }
}


function* askForSolicitudesList({ payload: history }) {
  const list = yield select((state=> state.salesorder.list.data.length))
  if(list === 0){
    yield put(getSolicitudes(history));
  }
}

export function* watchAskForSolicitudes() {
yield takeEvery(ASK_FOR_SOLICITUDES_LIST, askForSolicitudesList);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetTemplate),
        // fork(watchAskForSolicitudes),
        fork(watchGetSolicitudes)
    ]);
}