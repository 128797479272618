import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import bienesSagas from './bienes/saga'
import salesorderSaga from "./salesorder/saga"
import errorSaga from "./errors/saga"


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    bienesSagas(),
    salesorderSaga(),
    errorSaga(),
  ]);
}
